import axios from "axios";
import { setCourses } from "../slices/course-slice";

export const allCoursesPage = () => async (dispatch) => {
    const config = {
        headers: {
            'Content-type': 'application/json'
        }
    }
    try {
        const response = await axios.get('/api/courses', config);
        const courses = response.data?.courses;
        dispatch(setCourses(courses));
    } catch (error) {
        console.error('Error fetching courses:', error);
    }
};


export const courseDetailPage = (id) => async (dispatch) => {
    const config = {
        headers: {
            'Content-type': 'application/json'
        }
    }
    try {
        const response = await axios.get(`/api/course/${id}`, config);
        const courses = response.data?.courses;
        dispatch(setCourses(courses));
    } catch (error) {
        console.error('Error fetching course:', error);
    }
}