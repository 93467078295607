const { createSlice } = require('@reduxjs/toolkit');

const currencySlice = createSlice({
    name: "currency",
    initialState: {
        currencySymbol: "RM",
        currencyName: "MYR",
        currencyRate: 1
    },
    reducers: {
        setCurrency(state, action) {
            const currencyName = action.payload;

            if (currencyName === "USD") {
                return state = {
                    currencySymbol: "$",
                    currencyRate: 0.21,
                    currencyName
                };
            }
            if (currencyName === "MYR") {
                return state = {
                    currencySymbol: "RM",
                    currencyRate: 1,
                    currencyName
                };
            }
            if (currencyName === "SGD") {
                return state = {
                    currencySymbol: "s$",
                    currencyRate: 0.3,
                    currencyName
                };
            }
        }
    },
});

export const { setCurrency } = currencySlice.actions;
export default currencySlice.reducer;
