import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLogin: false,
    logToken: {},
    currentUser: {},
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLogToken(state, action) {
            state.logToken = action.payload;
        },
        setIsLogin(state, action) {
            state.isLogin = action.payload;
        },
        setCurrentUser(state, action) {
            state.currentUser = action.payload
        }
    },
});

export const { setLogToken, setIsLogin, setCurrentUser } = authSlice.actions;
const authReducer = authSlice.reducer;

export default authReducer;
